import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./Home";
import AiTabs from "./ai/AiTabs";

class App extends React.Component {
    render() {
        return (
            <Router>
                <Routes>

                    {/*Public Routes*/}

                    <Route path="/" element={<Home/>} exact/>
                    <Route exact path="/ai/load" element={<AiTabs/>}/>

                    {/* Add more routes as needed */}
                </Routes>
            </Router>
        );
    }
}

export default App;
