import React, {useState} from "react";
import "./AiTabs.css";

const AiTabs = () => {
    const [toggleState, setToggleState] = useState(1);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const getActiveClass = (index, className) =>
        toggleState === index ? className : "";

    function getH2() {
        return <>Lorem</>;
    }

    return (
        <div className="App">
            <div className="container table-responsive">
                <div className="tabsPageTitle">

                    Girify AI Apps

                </div>
                <ul className="tab-list">
                    <li
                        className={`tabs ${getActiveClass(1, "active-tabs")}`}
                        onClick={() => toggleTab(1)}
                    >
                        Chat AI Agent
                    </li>
                    <li
                        className={`tabs ${getActiveClass(2, "active-tabs")}`}
                        onClick={() => toggleTab(2)}
                    >
                        Generate AI Images
                    </li>
                    <li
                        className={`tabs ${getActiveClass(3, "active-tabs")}`}
                        onClick={() => toggleTab(3)}
                    >
                        AI Document Reader
                    </li>
                </ul>
                <div className="content-container">
                    <div className={`content ${getActiveClass(1, "active-content")}`}>
                        <h2>{getH2()}</h2>
                    </div>
                    <div className={`content ${getActiveClass(2, "active-content")}`}>
                        <h2>Ipsum</h2>
                    </div>
                    <div className={`content ${getActiveClass(3, "active-content")}`}>
                        <h2>Dolor</h2>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AiTabs;